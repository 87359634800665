import React, { useContext, useEffect, useState } from "react";
import Terminal, { ColorMode } from "react-terminal-ui";
import { Col, Row } from "reactstrap";
import Typography from "../../components/common/_typography";
import { Button } from "reactstrap";
import StyledPauseButton from "../../components/common/_pauseButton";
import { io } from "socket.io-client";
import { env } from "../../env";
import axiosAdapter from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";

const TerminalConnector = (props) => {
  const { showMessage } = useContext(SnackbarContext);

  const {
    closeModal,
    isTerminalButtonClicked,
    setIsTerminalButtonClicked,
    setModalOpen,
    configIdForTerminal,
  } = props;

  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isTerminalResumed, setIsTerminalResumed] = useState(false);
  const [statusMessage, setStatusMessage] = useState("connecting");
  const [topicName, setTopicName] = useState("");

  useEffect(() => {
    // Fetching Topic from ConfigId
    const setNewTopicNameResponse = setNewTopicName();

    if (setNewTopicNameResponse) {
      //Socket Logic
      const socketC = io(env.REACT_APP_SOCKET_IO_URL, { path: "/logs" });
      console.log(socketC, "socketC");

      setSocket(socketC);

      socketC.emit("startConsumer", { newEvent: topicName });

      socketC.on("event", (message) => {
        // console.log("Received message:", message);
        setMessages((prevMessages) => [...prevMessages, message]);
      });
      console.log("Component is mounting");
      return () => {
        console.log("component is unmounting");
        socketC.disconnect();
      };
    } else {
      showMessage("Failed to fetch topic name");
    }
  }, []);
  // console.log(socket, "socket");

  useEffect(() => {
    if (messages.length > 100) {
      setMessages((prevMessages) => prevMessages.slice(messages.length - 100));
    }

    if (!isTerminalResumed && messages.length) {
      setStatusMessage("connected");
    }
  }, [messages]);

  const setNewTopicName = async () => {
    try {
      let stringifiedData = JSON.stringify({
        connectorId: configIdForTerminal,
      });

      let setNewTopicNameResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getConfiguredConnector",
        stringifiedData
      );

      let data = JSON.parse(setNewTopicNameResponse.data.data.config);

      if (
        setNewTopicNameResponse.data.status == "OK" &&
        data.topic != undefined
      ) {
        setTopicName(data.topic);

        return true;
      } else if (
        setNewTopicNameResponse.data.status == "OK" &&
        data.topic === undefined
      ) {
        setModalOpen(false);
        showMessage("Topic Name not found");
        return false;
      }
    } catch (err) {
      console.log("err:", err);

      return false;
    }
  };

  const handleStartButton = async () => {
    if (
      statusMessage === "connecting" ||
      statusMessage === "connected" ||
      statusMessage !== "paused"
    ) {
      console.log("Terminal already started");
      showMessage("Terminal already started");
    } else {
      await socket.emit("resumeConsumer", { newEvent: "track" });

      if (isTerminalResumed && messages.length < 101) {
        setStatusMessage("connecting");
        setIsTerminalResumed(false);
      }

      console.log("Terminal reconnected");
      showMessage("Terminal reconnected");
    }
  };

  const setModalOpenFunction = (e) => {
    setModalOpen(e);

    if (isTerminalButtonClicked) {
      socket.emit("disconnectConsumer", { newEvent: "track" });
      setIsTerminalButtonClicked(false);
      setMessages([]);
      setStatusMessage("connecting");
    }
  };

  const handleStopButton = async () => {
    if (statusMessage === "paused") {
      console.log("Terminal already paused");
      showMessage("Terminal already paused");
    } else {
      await socket.emit("pauseConsumer", { newEvent: "track" });
      setIsTerminalResumed(true);
      setStatusMessage("paused");
      console.log("Terminal paused successfully");
      showMessage("Terminal paused successfully");
    }
  };

  return (
    <Row>
      <Col className="connectorsPageModalCloseBtn">
        <img
          src={closeModal}
          onClick={() => setModalOpenFunction(false)}
          height="30px"
          style={{
            paddingTop: "15px",
            paddingRight: "15px",
            cursor: "pointer",
          }}
        />
      </Col>
      <Row>
        <Col
          xs={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row>
            <Col className="beaconIndicatorGreen" />
          </Row>
        </Col>
        <Col
          xs={5}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            text="Input Data Source"
            tag="head-x-small"
            color="#FAFAFA"
          />
        </Col>
        <Col
          xs={1}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Row>
            <Col xs={12}>
              <StyledPauseButton
                onClick={handleStartButton}
                iconType={"play"}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={1}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Row>
            <Col xs={12}>
              <StyledPauseButton onClick={handleStopButton} iconType={"stop"} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Col xs={12} style={{ paddingTop: "30px", height: "300px" }}>
        <Row>
          <Col xs={12} style={{ padding: "15px" }}>
            <Button color="primary" disabled>
              <Typography
                key={statusMessage}
                text={[
                  <b style={{ color: "#8BF5FA" }}>Status : </b>,
                  statusMessage === "connecting"
                    ? "Terminal connecting..."
                    : statusMessage === "connected"
                    ? "Terminal started"
                    : statusMessage === "paused"
                    ? "Terminal paused"
                    : "",
                ]}
                tag="p-large"
                color="#FAFAFA"
              />
            </Button>
          </Col>
        </Row>
        <Terminal
          prompt=">"
          colorMode={ColorMode.Dark}
          height="31rem"
          maxWidth="50%"
        >
          <Col xs={2} style={{ paddingTop: "50px" }}>
            {messages.map((e, index) => (
              <Col xs={4} style={{ paddingTop: "20px" }}>
                <Typography
                  key={index}
                  text={e}
                  tag="p-small"
                  color="#FAFAFA"
                />
              </Col>
            ))}
          </Col>
        </Terminal>
      </Col>
    </Row>
  );
};

export default TerminalConnector;
