import { useContext, useState, useEffect } from "react";
import { ConnectorsContext } from "./WorkSpaceConnectorsMainPage";
import CloseIcon from "../../../src/assets/images/closeModal.png";
import {
  categoriesArray,
  subCategoriesArray,
} from "./WorkSpaceConnectorsUtils";

const ConnectorFilterModal = () => {
  const {
    filterModalOpen,
    handleFilterClick,
    updateConnectorsByFilters,
    tempCategoryArray,
    tempSubCategoryArray,
  } = useContext(ConnectorsContext);

  const [categoriesArr, setCategoriesArr] = useState(categoriesArray);
  const [subCategoriesArr, setSubCategoriesArr] = useState(subCategoriesArray);

  const getBackgroundColor = (categoryName) => {
    const category = categoriesArr.find((cat) => cat.title === categoryName);
    if (category) {
      switch (category.color) {
        case "yellow":
          return "yellow-gradient";
        case "pink":
          return "pink-gradient";
        case "orange":
          return "orange-gradient";
        default:
          return "";
      }
    }
    return "";
  };

  useEffect(() => {
    if (tempCategoryArray.length) {
      setCategoriesArr(tempCategoryArray);
    }

    if (tempSubCategoryArray.length) {
      setSubCategoriesArr(tempSubCategoryArray);
    }
  }, [!filterModalOpen]);

  const handleCheckBoxClick = (categoryTitle, isCategorySelected) => {
    const updatedCategoriesArr = categoriesArr.map((eachObj) => {
      //If isCategorySelected is true, make all Sub Categories list's isChecked false
      let updatedSubCatArray;
      if (!isCategorySelected) {
        updatedSubCatArray = subCategoriesArr.map((eachSubCat) => {
          if (eachSubCat.title === categoryTitle) {
            const updatedLists = eachSubCat.lists.map((list) => {
              return { ...list, isChecked: true };
            });
            return { ...eachSubCat, lists: updatedLists };
          } else {
            return eachSubCat;
          }
        });
      } else {
        updatedSubCatArray = subCategoriesArr.map((eachSubCat) => {
          if (eachSubCat.title === categoryTitle) {
            const updatedLists = eachSubCat.lists.map((list) => {
              return { ...list, isChecked: false };
            });
            return { ...eachSubCat, lists: updatedLists };
          } else {
            return eachSubCat;
          }
        });
      }
      setSubCategoriesArr(updatedSubCatArray);

      //Making isSelected true false of Categories Array
      if (eachObj.title === categoryTitle) {
        return { ...eachObj, isSelected: !eachObj.isSelected }; // Toggle isSelected value
      } else {
        return eachObj;
      }
    });

    setCategoriesArr(updatedCategoriesArr);
  };

  const handleSubCategoryCheckBoxClick = (subCatId, subCatListId) => {
    const updatedSubCatArray = subCategoriesArr.map((eachSubCat) => {
      if (eachSubCat.id === subCatId) {
        const updatedLists = eachSubCat.lists.map((list) => {
          if (list.listId === subCatListId) {
            return { ...list, isChecked: !list.isChecked };
          } else {
            return list;
          }
        });

        return { ...eachSubCat, lists: updatedLists };
      } else {
        return eachSubCat;
      }
    });

    setSubCategoriesArr(updatedSubCatArray);
  };

  const handleClearButton = () => {
    const clearedSubCategoriesArray = subCategoriesArray.map((subCategory) => ({
      ...subCategory,
      lists: subCategory.lists.map((list) => ({
        ...list,
        isChecked: false,
      })),
    }));

    const clearedCategoriesArray = categoriesArray.map((category) => ({
      ...category,
      isSelected: false,
    }));

    setSubCategoriesArr(clearedSubCategoriesArray);
    setCategoriesArr(clearedCategoriesArray);
  };

  const handleApplyButton = () => {
    updateConnectorsByFilters(categoriesArr, subCategoriesArr);
  };

  const handleModalClose = () => {
    handleFilterClick();
  };

  // Get the selected categories
  const selectedCategories = categoriesArr.filter(
    (category) => category.isSelected
  );

  return (
    <dialog className="wsFilterModal">
      <div className="wsFilterMainContainer">
        <div className="wsFilterMainContainerRow1">
          <div className="wsFilterRow1Title">
            <p>Advanced Filters</p>
          </div>
          <div
            className="wsFilterRow1CloseButton"
            onClick={() => handleModalClose()}
          >
            <img src={CloseIcon} alt="" />
          </div>
        </div>
        <div className="wsFilterMainContainerRow2">
          <div className="wsFilterMainContainerRow2LeftArea">
            <div className="wsFilterModalCategoriesTitleArea">
              <p>Categories</p>
            </div>

            {categoriesArr.map((eachCat) => {
              return (
                <div
                  className="wsFilterModalEachCategoriesArea"
                  key={eachCat.id}
                >
                  <div
                    className="wsFilterModalCategoriesAreaCheckBox"
                    onClick={() => {
                      handleCheckBoxClick(eachCat.title, eachCat.isSelected);
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={eachCat.isSelected}
                      className="wsFilterModalCategoriesAreaCheckBoxInput"
                    />
                  </div>

                  <div
                    className={`wsFilterModalCategoriesAreaColorBox ${getBackgroundColor(eachCat.title)}`}
                  ></div>
                  <div className="wsFilterModalCategoriesAreaTextBox">
                    <p>{eachCat.title}</p>
                  </div>
                </div>
              );
            })}
            <div className="wsFilterModalCategoriesTransformsArea"></div>
            <div className="wsFilterModalCategoriesOutputArea"></div>
          </div>
          <div className="wsFilterMainContainerRow2RightArea">
            {/* Conditionally render based on the selected categories */}
            {selectedCategories.length > 0 ? (
              selectedCategories.map((category) => (
                <div className="wsFilterModalOptionsArea1" key={category.id}>
                  {subCategoriesArr.map((eachSubCat) => {
                    return (
                      eachSubCat.title == category.title && (
                        <div className="wsFilterModalOptionsArea1MainBox">
                          <div className="wsFilterModalOptionsArea1Row1">
                            <div
                              wsFilterModalOptionsArea1TitleColorBar
                              className={`wsFilterModalOptionsArea1TitleColorBar ${getBackgroundColor(eachSubCat.title)}`}>
                            </div>
                            <div className="wsFilterModalOptionsArea1Title">
                              <p>{eachSubCat.title} Sub Categories</p>
                            </div>
                          </div>

                          <div className="wsFilterModalSubCategoriesArea">
                            {eachSubCat.lists.map((allLists) => {
                              return (
                                <div
                                  className="wsFilterModalSubCategoriesAreaMainBox"
                                  onClick={() => {
                                    handleSubCategoryCheckBoxClick(
                                      eachSubCat.id,
                                      allLists.listId
                                    );
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={allLists.isChecked}
                                    className="wsFilterModalSubCategoriesAreaMainBoxInput"
                                  ></input>
                                  <label className="wsFilterModalSubCategoriesAreaMainBoxLabel">
                                    {allLists.listTitle}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              ))
            ) : (
              <div className="wsFilterModalOptionsArea2">
                <p>Select a Category to view options</p>
              </div>
            )}
          </div>
        </div>
        <div className="wsFilterMainContainerRow3">
          <div
            className="wsFilterModalClearButton"
            onClick={() => {
              handleClearButton();
            }}
          >
            <p>Clear</p>
          </div>
          <div
            className="wsFilterModalApplyButton"
            onClick={() => {
              handleApplyButton();
            }}
          >
            <p>Apply</p>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default ConnectorFilterModal;
