import { Col, Row, Spinner } from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { Input } from "reactstrap";
import Button from "../../components/common/_button";
import Typography from "../../components/common/_typography";
import InputField from "../../components/common/_loginInputField";
import OnBoardingDataSourcePipelineSetup from "../OnboardingPages/OnBoardingDataSourcePipelineSetup";
import styled from "styled-components";
import ConnectorsIcon from "../../assets/images/ConnectorsIcon.svg";
import DataBaseIcon from "../../assets/images/DataBaseIcon.svg";
import CustomCoding from "../../assets/images/customCoding.png";
import TickIcon from "../../assets/images/task_alt_white.svg";
import DeviceListToConfigure from "../../components/onBoardingComponents/_deviceListToConfigure";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import CondenseEditor from "../../components/editorComponents/CondenseEditor";
import ConnectorsCard from "../../components/connectorsComponents/_connectorsCard";
import ConfigureDevice from "../OnboardingPages/ConfigureDevice";
import axiosAdapter from "../../utils";
import { env } from "../../env";

const AddedNewConnectorsSkeleton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
`;

const AddedNewConnectorsCard = styled.div`
  height: 25rem;
  width: 80%;
  background: #232323;
  border-radius: 10px;
  cursor: pointer;
`;

const AddedNewConnectorsDescription = styled.div`
  background: red;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-20%);
  }
`;

const AddedNewConnectorsDetails = (props) => {
  const {
    connectorType,
    selectedDescription,
    selectedCardIndex,
    currentIndex,
    selectedCard,
  } = props;

  let selectedIcon;
  switch (connectorType) {
    case "Connectors":
      selectedIcon = ConnectorsIcon;
      break;
    case "Databases":
      selectedIcon = DataBaseIcon;
      break;
    case "Custom":
      selectedIcon = CustomCoding;
      break;
  }

  let isHovered = currentIndex === selectedCardIndex;

  return (
    <Row>
      <Col
        xs={8}
        style={{ display: "flex", paddingTop: "1rem", paddingLeft: "2rem" }}
      >
        <img src={selectedIcon} height="60px" />
      </Col>
      {currentIndex == selectedCard && (
        <Col
          xs={4}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <img src={TickIcon} height="30px" />
        </Col>
      )}
      <Col
        xs={12}
        style={{
          display: "flex",
          alignItems: "end",
          padding: "2rem",
          height: "20rem",
        }}
      >
        <Row>
          <Col
            xs={6}
            className={isHovered ? "selectedConnectorDescription" : ""}
          >
            <Typography
              tag="head-x-small"
              text={connectorType}
              color="#fafafa"
            />
          </Col>
          <Col
            xs={12}
            className={isHovered ? "selectedConnectorDescription" : ""}
          >
            {isHovered && (
              <Typography
                tag="p-small"
                text={selectedDescription}
                color="#fafafa"
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const ConnectorsAddedSource = (props) => {
  const { showMessage } = useContext(SnackbarContext);

  let newlyCreatedConfigId;

  const { workspaceLayout, nodeConfigIds } = props;
  const [selectedCardDescription, setSelectedCardDescription] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [multiStageStatus, setMultiStageStatus] = useState(1);
  const [headerText, setHeaderText] = useState(null);
  const [descriprionText, setDescriprionText] = useState(null);
  const [selectedCardTitle, setSelectedCardTitle] = useState(null);
  const [title, setTitle] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isSelectedSourceValid, setIsSelectedSourceValid] = useState(true);
  const [listAllMasterConnectors, setListAllMasterConnectors] = useState(null);
  const [listAllStoreConnectors, setListAllStoreConnectors] = useState(null);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [selectedCardDetails, setSelectedCardDetails] = useState({});
  const [selectedInputEdges, setSelectedInputEdges] = useState([]);
  const [editableNewWorkspace, setEditableNewWorkspace] = useState({});

  const [configs, setConfigs] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [isConfigApisBeingCalled, setIsConfigApisBeingCalled] = useState(false);

  useEffect(() => {
    if (multiStageStatus == 6) {
      fetchConfigs();
    }
  }, [multiStageStatus == 6]);

  const fetchConfigs = async () => {
    const { name } = selectedCardDetails;

    try {
      let stringifiedData = JSON.stringify({
        connectorName: name,
      });

      let fetchConfigResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getConnectorConfigs",
        stringifiedData
      );

      setConfigs(fetchConfigResponse.data.data.config);
    } catch (err) {
      console.log("err:", err);
    }
  };

  const handleConfigFieldsInputChange = (e, index) => {
    const { value } = e.target;

    // Update the input values array with the new value at the specified index
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = value;
    setInputValues(updatedInputValues);
  };

  const handleConfigCheck = () => {
    const isFormValid = configs.every((config, index) => {
      const { type, label, isRequired } = config;
      const value = inputValues[index] || "";

      if (!isRequired) {
        return true; // Skip checking for non-required fields
      }

      if (type === "number") {
        const numericValue = Number(value);
        if (!isNaN(numericValue) && numericValue.toString().length >= 3) {
          return true;
        }
      } else if (type === "text") {
        if (typeof value === "string" && value.length >= 3) {
          return true;
        }
      } else {
        return true; // Skip validation for other types
      }

      showMessage(
        `${label} field must be 3 ${
          type == "text" ? "alphabet" : "number"
        } characters.`
      );
      return false;
    });

    if (isFormValid) {
      return true;
    }
  };

  const combineConfigAndInputValues = () => {
    const configObject = {};

    configs.forEach((config, index) => {
      const { name } = config;
      configObject[name] = inputValues[index] || "";
    });

    return configObject;
  };

  const setConnectorConfigs = async () => {
    setIsConfigApisBeingCalled(true);
    let combineResponse = combineConfigAndInputValues();

    try {
      let stringifiedData = JSON.stringify({
        connectorName: `${selectedCardDetails.name}`,
        config: combineResponse,
      });

      let setConnectorConfigResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setConnectorConfigs",
        stringifiedData
      );

      if (setConnectorConfigResponse.data.status == "OK") {
        newlyCreatedConfigId = setConnectorConfigResponse.data.data.id;
        setInputValues([]);

        return true;
      }
    } catch (err) {
      console.log("err:", err);
      setInputValues([]);

      return false;
    }
  };

  const testConnectorConfig = async () => {
    console.log("newlyCreatedConfigId:", newlyCreatedConfigId);

    try {
      let stringifiedData = JSON.stringify({
        id: newlyCreatedConfigId,
      });

      let testConnectorConfigResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/testConnectorConfigs",
        stringifiedData
      );

      if (testConnectorConfigResponse.data.status == "OK") {
        setIsConfigApisBeingCalled(false);

        return true;
      }
    } catch (err) {
      console.log("err:", err);
      setInputValues([]);
      setIsConfigApisBeingCalled(false);

      return false;
    }
  };

  const listOfConnectors = ["Connectors", "Databases", "Custom"];
  // console.log("selectedCardTitle", selectedCardTitle);
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleSelectedSourceChange = (event) => {
    setSelectedSource(event.target.value);
  };

  const checkValidations = () => {
    const regex = new RegExp(/^(?=.{6,12}$)(?=.*[a-zA-Z])[a-zA-Z\d ]+$/);
    if (regex.test(title) == false) {
      setIsTitleValid(false);
      showMessage("Title must be 6 to 12 characters. Only 2 spaces allowed.");

      return false;
    } else if (regex.test(selectedSource) == false) {
      setIsTitleValid(true);
      setIsSelectedSourceValid(false);
      showMessage(
        "Source name must be 6 to 12  characters. Only 2 spaces allowed."
      );

      return false;
    } else {
      setIsTitleValid(true);
      setIsSelectedSourceValid(true);
      return true;
    }
  };

  const handleSelectedCard = (e, i) => {
    setSelectedCard(selectedCard === i ? null : i);
    setSelectedCardTitle(selectedCardTitle === e ? null : e);
  };

  /* handleSourceSelectedContinue handles 
  the stages of modal for adding the transforms */

  const handleSourceSelectedContinue = async (currentNumber) => {
    // console.log("currentNumber:", currentNumber);
    // console.log("selectedCardTitle:", selectedCardTitle);

    if (selectedCardTitle == "Custom" && multiStageStatus < 3) {
      setMultiStageStatus(3);
    } else if (selectedCardTitle == "Custom" && multiStageStatus == 3) {
      setMultiStageStatus(currentNumber + 1);
    } else if (selectedCardTitle == "Custom" && multiStageStatus == 4) {
      let validationResult = checkValidations();
      if (validationResult == true) {
        console.log("final destination of CUSTOM", title, selectedSource);
        // setWorkSpaces();
        setMultiStageStatus(currentNumber + 1);
      }
    } else if (selectedCardTitle != "Custom" && multiStageStatus < 2) {
      setMultiStageStatus(2);
    } else if (selectedCardTitle != "Custom" && multiStageStatus == 2) {
      // setWorkSpaces();
      setMultiStageStatus(6);
    } else if (
      (selectedCardTitle == "Connectors" || selectedCardTitle == "Databases") &&
      currentNumber == 6
    ) {
      const configCheckResponse = handleConfigCheck();

      if (configCheckResponse) {
        let setConnectorConfigResponse = await setConnectorConfigs();

        if (setConnectorConfigResponse) {
          let testConnectorConfigResponse = await testConnectorConfig();

          if (testConnectorConfigResponse) {
            setMultiStageStatus(5);
          } else {
            showMessage("Failed to test Connectors Config");
          }
        } else {
          showMessage("Failed to set Connectors Config");
        }
      }
    } else if (multiStageStatus == 5) {
      // ! Need to add the logic for the edge selection Modal
      await setWorkSpaces();
    }

    if (selectedCardTitle === "Connectors") {
      async function fetchData() {
        let listStreamConnectors = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "admin/listAllMasterStreamConnectors"
        );
        console.log("listStreamConnectors", listStreamConnectors.data);
        if (listStreamConnectors && listStreamConnectors.data) {
          setListAllMasterConnectors(listStreamConnectors.data);
        }
      }
      fetchData();
    }

    if (selectedCardTitle === "Databases") {
      console.log("Inside the databases");
      async function fetchData() {
        let listStoreConnectors = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "admin/listAllMasterStoreConnectors"
        );
        console.log("listStreamConnectors", listStoreConnectors.data);
        if (listStoreConnectors && listStoreConnectors.data) {
          setListAllStoreConnectors(listStoreConnectors.data);
        }
      }
      fetchData();
    }
  };

  const handleInputConnectors = (e, connectionType) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    if (connectionType == "Input") {
      setSelectedInputEdges(selectedValues);
    }
  };
  // showMessage("Edge is already connected to Input");

  // node: await editableWorkspace.node.map((nodeSet) => {
  //   console.log("This is nodeSet",nodeSet)
  //   let id = JSON.parse(nodeSet.id);

  //   if (id === nodeLen) {
  //     lastNodePosition = nodeSet.position;
  //     return {
  //       ...nodeSet,
  //       type: "custom",
  //       data: {
  //         ...nodeSet.data,
  //         label: "input",
  //         isConnector: true,
  //         title: selectedCardTitle === "Custom" ? title : "Transformer",
  //         status: "Running",
  //         description: selectedCardDetails.description
  //           ? selectedCardDetails.description
  //           : "",
  //         sourceName:
  //           selectedCardTitle === "Custom"
  //             ? selectedSource
  //             : selectedCardDetails.name,
  //         connectionType: selectedCardDetails.code,
  //       },
  //     };
  //   }
  //   return nodeSet;
  // }),

  const handleAddNode = async (editableWorkspace, nodeLen, edgesLen) => {
    let lastNodePosition = {};
    let updatedStringifyData = {
      ...editableWorkspace,
    };
    await updatedStringifyData.node.push({
      id: JSON.stringify(nodeLen + 1),
      type: "custom",
      data: {
        label: "input",
        isConnector: false,
        title: selectedCardTitle === "Custom" ? title : "Transformer",
        status: "Running",
        description: selectedCardDetails.description
          ? selectedCardDetails.description
          : "",
        sourceName:
          selectedCardTitle === "Custom"
            ? selectedSource
            : selectedCardDetails.name,
        connectionType: selectedCardDetails.code,
        configId: newlyCreatedConfigId,
      },
      targetPosition: "left",
      sourcePosition: "right",
      position: {
        x: lastNodePosition.x + 420,
        y: nodeLen >= 3 ? 80 : 30,
      },
    });

    for (let value of selectedInputEdges) {
      await updatedStringifyData.edges.push({
        id: JSON.stringify(nodeLen + 1),
        // animated: true,
        id: `reactflow__edge-${value}-${nodeLen + 1}`,
        source: `${value}`,
        sourceHandle: null,
        target: `${nodeLen + 1}`,
        targetHandle: null,
        // type: "smoothstep",
      });
    }

    console.log("updatedStringifyData---->>> After", updatedStringifyData);

    return updatedStringifyData;
  };

  const setWorkSpaces = async () => {
    //All unique configIds
    const uniqueNodeConfigIds = Array.from(new Set(nodeConfigIds));
    console.log("uniqueNodeConfigIds:", uniqueNodeConfigIds);

    //SetWorkSpace Logic
    setEditableNewWorkspace(workspaceLayout);
    let editableWorkspaceLayout = workspaceLayout;
    let nodesLength = editableWorkspaceLayout.node.length;
    let edgesLength = editableWorkspaceLayout.edges.length;
    let updatedNodeEdges;
    if (nodesLength > 0) {
      updatedNodeEdges = await handleAddNode(
        editableWorkspaceLayout,
        nodesLength,
        edgesLength
      );
    }

    let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");
    let stringifyData = {
      id: selectedWorkspaceId,
      layout: {
        node: updatedNodeEdges.node,
        edges: updatedNodeEdges.edges,
      },
    };
    console.log("stringifyData", stringifyData);

    let setWorkspaceList = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/setWorkSpace",
      stringifyData
    );
    if (setWorkspaceList && setWorkspaceList.data.status == "OK") {
      props.modalAddedConnectorsFunction(false);
      showMessage("Connector Configured Successfully", "success");
    }
  };

  const handleBackButton = () => {
    // Reference for current page sequences -- 1,2,6,5
    if (selectedCardTitle === "Custom" && multiStageStatus == 3) {
      setMultiStageStatus(1);
    } else if (selectedCardTitle != "Custom" && multiStageStatus == 6) {
      setMultiStageStatus(2);
    } else if (selectedCardTitle != "Custom" && multiStageStatus == 5) {
      setMultiStageStatus(6);
    } else {
      setMultiStageStatus(multiStageStatus - 1);
    }
  };

  const handleSelectedDescription = (e, i) => {
    if (e === null) {
      setSelectedCardIndex(null);
    } else {
      setSelectedCardIndex(i);
    }
    switch (e) {
      case "Connectors":
        setSelectedCardDescription("Please select a Connector");
        break;
      case "Databases":
        setSelectedCardDescription("Please select a Database");
        break;
      case "Custom":
        setSelectedCardDescription("Please write your custom code");
        break;
      default:
        setSelectedCardDescription(null);
        break;
    }
  };

  useEffect(() => {
    // console.log("selectedCardTitle--->>>>", selectedCardTitle);

    switch (multiStageStatus) {
      case 1:
        setHeaderText("Select the Source of your Data");
        setDescriprionText("Choose the data source that you wish to use");
        break;

      case 2:
        setHeaderText(`Select your ${selectedCardTitle} Model`);
        setDescriprionText(
          `Choose the ${selectedCardTitle} that you wish to use`
        );
        break;

      case 3:
        setHeaderText("Code Editor");
        setDescriprionText("");
        break;

      case 4:
        setHeaderText("Condense Code Editor");
        setDescriprionText("Fill the required information");
        break;

      case 5:
        setHeaderText("Select your transform connection");
        setDescriprionText("Choose a edge to connect your transform");
        break;

      case 6:
        setHeaderText("Configure the Device");
        setDescriprionText("");
        break;
    }
  }, [multiStageStatus]);

  const handleCardSelect = (
    id,
    name,
    manufact_name,
    devicePort,
    networkType,
    status,
    description,
    code
  ) => {
    // handleDeviceSelected(name, manufact_name, devicePort, networkType, status);
    if (id === selectedCardId) {
      setSelectedCardId(null);
    } else {
      setSelectedCardId(id);
    }
    setSelectedCardDetails({
      id: id,
      name: name,
      manufact_name: manufact_name,
      description: description,
      code: code,
      port: devicePort,
    });
  };

  return (
    <Row>
      {headerText != "" && (
        <Col xs={12}>
          <Typography tag="head-x-small" text={headerText} color="#fafafa" />
        </Col>
      )}

      {descriprionText != "" && (
        <Col xs={12} style={{ paddingTop: "1rem" }}>
          <Typography tag="p-medium" text={descriprionText} color="#fafafa" />
        </Col>
      )}

      <Col xs={12} style={{ paddingTop: "5vh" }}>
        {multiStageStatus == 1 && (
          <Row>
            {listOfConnectors.map((e, i) => (
              <Col
                xs={4}
                style={{ alignItems: "center" }}
                onMouseEnter={() => handleSelectedDescription(e, i)}
                onMouseLeave={() => handleSelectedDescription(null)}
              >
                <AddedNewConnectorsSkeleton>
                  <AddedNewConnectorsCard>
                    <Col onClick={() => handleSelectedCard(e, i)}>
                      <AddedNewConnectorsDetails
                        selectedDescription={selectedCardDescription}
                        selectedCardIndex={selectedCardIndex}
                        currentIndex={i}
                        connectorType={e}
                        selectedCard={selectedCard}
                      />
                    </Col>
                  </AddedNewConnectorsCard>
                </AddedNewConnectorsSkeleton>
              </Col>
            ))}
          </Row>
        )}

        {multiStageStatus == 3 && <CondenseEditor />}

        {multiStageStatus == 4 && (
          <Row style={{ height: "28rem" }}>
            <Col xs={5} style={{}}>
              <Col style={{ padding: "0px 0px 0px 0px", width: "25rem" }}>
                <InputField
                  throwError={!isTitleValid}
                  name="title"
                  placeholder="Enter Title"
                  type="text"
                  value={title}
                  onChange={handleTitleChange}
                />
              </Col>
              <Col style={{ padding: "15px 0px 0px 0px", width: "25rem" }}>
                <InputField
                  throwError={!isSelectedSourceValid}
                  name="selectedSource"
                  placeholder="Enter Selected Source"
                  type="text"
                  value={selectedSource}
                  onChange={handleSelectedSourceChange}
                />
              </Col>
            </Col>
            <Col xs={5}>
              <Typography tag="head-x-small" text={"Preview"} color="#fafafa" />
              <Col
                style={{
                  backgroundColor: "#3c3c3c",
                  height: "180px",
                }}
              >
                <Col
                  style={{
                    margin: "10px 10px 0px 10px",
                    padding: "10px 0px 0px 0px",
                  }}
                >
                  <ConnectorsCard
                    data={{
                      title: title,
                      sourceName: selectedSource,
                      status: "Running",
                      deviceModel: "Not Applicable",
                      port: "Not Applicable",
                    }}
                  />
                </Col>
              </Col>
            </Col>
          </Row>
        )}

        {multiStageStatus == 5 && (
          <Row style={{ height: "28rem" }}>
            <Col xs={5} style={{ display: "flex", alignItems: "center" }}>
              <Col
                xs={10}
                style={{
                  backgroundColor: "#3c3c3c",
                  height: "180px",
                }}
              >
                <Row>
                  <Col
                    style={{
                      margin: "10px 5px 0px 5px",
                    }}
                  >
                    <ConnectorsCard
                      data={{
                        title: selectedCardDetails.name,
                        sourceName: "Not Applicable",
                        status: "Running",
                        deviceModel: selectedCardDetails.description,
                        port: "Not Applicable",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Col>
            <Col xs={6}>
              <Row>
                <Col xs={12}>
                  <Typography
                    tag="head-x-small"
                    text={"Select Edges for the selected transform :-"}
                    color="#fafafa"
                  />
                </Col>
                <Col
                  xs={12}
                  style={{
                    height: "12rem",
                    paddingTop: "30px",
                  }}
                >
                  <Row>
                    <Col xs={12} style={{ paddingBottom: "5px" }}>
                      <Typography
                        tag="p-small"
                        text={"Input Connection :- "}
                        color="#fafafa"
                      />
                    </Col>
                    <Col xs={12}>
                      <Input
                        style={{ height: "10rem" }}
                        type="select"
                        multiple
                        value={selectedInputEdges}
                        onChange={(e) => handleInputConnectors(e, "Input")}
                      >
                        {workspaceLayout.node.map((e) => (
                          <option value={e.id}>
                            {e.id + " - " + e.data.sourceName}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {multiStageStatus === 6 &&
          (selectedCardTitle === "Connectors" ||
            selectedCardTitle === "Databases") && (
            <ConfigureDevice
              deviceName={selectedCardDetails.name}
              configs={configs}
              handleConfigFieldsInputChange={handleConfigFieldsInputChange}
              inputValues={inputValues}
              isConfigApisBeingCalled={isConfigApisBeingCalled}
            />
          )}

        {multiStageStatus === 2 &&
          selectedCard !== 2 &&
          selectedCardTitle === "Connectors" && (
            <Row>
              {listAllMasterConnectors &&
                listAllMasterConnectors.map((e) => (
                  <Col xs={6} md={4} lg={2} style={{ paddingTop: "18px" }}>
                    <DeviceListToConfigure
                      name={e.name}
                      id={e.id}
                      icon_link={e.icon_link}
                      isSelected={e.id === selectedCardId}
                      description={e.description}
                      code={e.code}
                      onSelect={handleCardSelect}
                    />
                  </Col>
                ))}
            </Row>
          )}

        {multiStageStatus === 2 &&
          selectedCard !== 2 &&
          selectedCardTitle === "Databases" && (
            <Row>
              {listAllStoreConnectors &&
                listAllStoreConnectors.map((e) => (
                  <Col xs={6} md={4} lg={2} style={{ paddingTop: "18px" }}>
                    <DeviceListToConfigure
                      name={e.name}
                      id={e.id}
                      icon_link={e.icon_link}
                      isSelected={e.id === selectedCardId}
                      description={e.description}
                      code={e.code}
                      onSelect={handleCardSelect}
                    />
                  </Col>
                ))}
            </Row>
          )}

        <Row>
          {isConfigApisBeingCalled ? (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                padding: "20px 170px 20px 20px",
              }}
            >
              <Spinner color="light" />
            </div>
          ) : (
            <Col
              xs={12}
              style={{
                display: "flex",
                justifyContent: "end",
                padding: "20px",
              }}
            >
              {multiStageStatus > 1 && multiStageStatus != 5 && (
                <Row>
                  <Col style={{ paddingRight: "50px" }}>
                    <Button
                      disabled={
                        selectedCard === null || isConfigApisBeingCalled == true
                      }
                      onClickEvent={handleBackButton}
                      tag={"secondary"}
                      btnText={"Back"}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Button
                    disabled={
                      (multiStageStatus == "1" && selectedCard === null) ||
                      (multiStageStatus == "2" && selectedCardId === null) ||
                      (multiStageStatus == "5" &&
                        selectedInputEdges.length === 0)
                    }
                    onClickEvent={() =>
                      handleSourceSelectedContinue(multiStageStatus)
                    }
                    tag={"primary"}
                    btnText={multiStageStatus != 5 ? "Continue" : "Done"}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ConnectorsAddedSource;
