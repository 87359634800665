import { useContext, useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { ConnectorsContext } from "./WorkSpaceConnectorsMainPage";
import NewButton from "../../components/common/_newButton";
import SetupCard from "../../components/onBoardingComponents/_setupCard";
import ConfigureCard from "../../components/onBoardingComponents/_configureCard";
import ConnectorsCard from "../../components/connectorsComponents/_connectorsCard";
import ConnectorCardDashed from "../../components/connectorsComponents/_connectorsCardDashed";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import dagre from "dagre";
import ReactFlow, { ConnectionLineType } from "reactflow";

let initialNodes = [];
let initialEdges = [];
let configIdArray = [];
const nodeWidth = 400;
const nodeHeight = 150;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes, edges) => {
  if (nodes && edges) {
    // Find nodes that have no outgoing edges
    const nodesWithoutTarget = nodes
      .filter((node) => {
        return !edges.some((edge) => edge.source === node.id);
      })
      .map((node) => node.id);

    // console.log("nodesWithoutTarget:", nodesWithoutTarget);

    //Graph Related Logics
    dagreGraph.setGraph({ rankdir: "LR" });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node, i) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = "left";
      node.sourcePosition = "right";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
      if (node.position.x == 0 && node.position.y == 0) {
        node.position.x = 30;
        node.position.y = 30;
      } else if (node.position.x == 0) {
        node.position.x = 30;
      } else if (node.position.y == 0) {
        node.position.y = 30;
      }

      // Modifying data object of node
      // Check if the node's id is present in nodesWithoutTarget array
      if (nodes.length > 1) {
        node.data.isNodeWithoutTarget = nodesWithoutTarget.includes(node.id);
      } else {
        node.data.isNodeWithoutTarget = true;
      }

      //Inserting key "firstNode" = true, if node id is 1
      //This is being inserted only to hide , left side dot from node card from UI, if node card is first one
      if (node.id == 1) {
        node.data.firstNode = true;
      } else {
        node.data.firstNode = false;
      }

      //Picking all config Ids
      if (node.data?.configId) {
        configIdArray.push(node.data?.configId);
      }

      return node;
    });

    return { nodes, edges };
  }
};

const nodeTypes = {
  customDashed: ConnectorCardDashed,
  custom: ConnectorsCard,
};

const getInitialNode = (
  e,
  selectedConnectorName,
  selectedConnectorType,
  currentConfigId,
  selectedWorkspaceId
) => {
  let titleLable;
  let lastNodePosition = {};
  switch (selectedConnectorType) {
    case "INPUT":
      titleLable = "Input Data Source";
      break;
    case "TRANSFORM":
      titleLable = "Transformer";
      break;
    case "OUTPUT":
      titleLable = "Output Data Source";
      break;
    default:
      break;
  }
  let stringifyData = {
    id: selectedWorkspaceId,
    layout: {
      node: [
        {
          id: "1",
          type: "customDashed",
          data: {
            label: selectedConnectorType,
            isConnector: false,
            title: titleLable,
            status: "Running",
            description: `${selectedConnectorName} ${selectedConnectorType} Connector `,
            sourceName: selectedConnectorName,
            connectionType: "HTTP",
            isNodeWithoutTarget: false,
            firstNode: false,
            configId: currentConfigId,
            targetPosition: "left",
            sourcePosition: "right",
            position: {
              x: lastNodePosition.x + 420,
              y: e?.node?.length >= 3 ? 80 : 30,
            },
          },
        },
      ],
      edges: [
        {
          id: "reactflow__edge-1-2",
          source: "1",
          sourceHandle: null,
          targetHandle: null,
        },
      ],
    },
  };
  return stringifyData;
};

const getAdditionalNode = (
  e,
  selectedConnectorName,
  selectedConnectorType,
  currentConfigId
) => {
  let titleLable;
  let lastNodePosition = {};
  switch (selectedConnectorType) {
    case "INPUT":
      titleLable = "Input Data Source";
      break;
    case "TRANSFORM":
      titleLable = "Transformer";
      break;
    case "OUTPUT":
      titleLable = "Output Data Source";
      break;
    default:
      break;
  }
  const newConnectionNode = {
    id: (e.node.length + 1).toString(),
    type: "customDashed",
    data: {
      label: selectedConnectorType,
      isConnector: false,
      title: titleLable,
      status: "Running",
      description: `${selectedConnectorName} ${selectedConnectorType} Connector `,
      sourceName: selectedConnectorName,
      connectionType: "HTTP",
      isNodeWithoutTarget: false,
      firstNode: false,
      configId: currentConfigId,
      targetPosition: "left",
      sourcePosition: "right",
      position: {
        x: lastNodePosition.x + 420,
        y: e.node.length >= 3 ? 80 : 30,
      },
    },
  };
  e.node.push(newConnectionNode);
  return e;
};

const ConnectorsConfigurationPage = (props) => {
  const {
    workspaceDetails,
    selectedConnectorName,
    selectedConnectorType,
    currentConfigId,
    selectedWorkspaceId,
  } = props;
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [selectedNodeIds, setSelectedNodeIds] = useState([]);
  const { showMessage } = useContext(SnackbarContext);

  console.log("nodes--------->>>", nodes);
  console.log("edges----------->>>>", edges);

  const {
    inputValues,
    configs,
    handleConfigFieldsInputChange,
    handleCancelClick,
    handleNextClick,
    disableConfigInputs,
    isConfigApisBeingCalled,
  } = useContext(ConnectorsContext);

  useEffect(() => {
    let fetchedResult;
    if (workspaceDetails && workspaceDetails.node && workspaceDetails.edges) {
      getAdditionalNode(
        workspaceDetails,
        selectedConnectorName,
        selectedConnectorType,
        currentConfigId
      );
      fetchedResult = getLayoutedElements(
        workspaceDetails.node,
        workspaceDetails.edges
      );
    } else if (currentConfigId && selectedWorkspaceId) {
      let getInitalData = getInitialNode(
        workspaceDetails,
        selectedConnectorName,
        selectedConnectorType,
        currentConfigId,
        selectedWorkspaceId
      );
      fetchedResult = getLayoutedElements(
        getInitalData.layout.node,
        getInitalData.layout.edges
      );
    }
    if (fetchedResult) {
      setNodes(fetchedResult.nodes);
      setEdges(fetchedResult.edges);
    }
  }, [workspaceDetails, currentConfigId]);

  const handleButtonClick = async (e) => {
    if (e === 1) {
      handleNextClick(e);
      return;
    }

    if (e === 2) {
      if (selectedNodeIds && selectedNodeIds.length > 0) {
        const selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");
        const updatedNodes = nodes.map((node) =>
          node.type === "customDashed" ? { ...node, type: "custom" } : node
        );

        const stringifyData = {
          id: selectedWorkspaceId,
          layout: {
            node: updatedNodes,
            edges: edges,
          },
        };

        handleNextClick(e, selectedWorkspaceId, stringifyData);
      } else {
        showMessage("Please select a node to connect");
      }
    }
  };

  const handleNodeClick = (x, y) => {
    let nodeId = [y.id];
    let finalNodeId = nodes.length.toString();
    let addedNewEdgeConnection;

    if (!selectedNodeIds.includes(nodeId.toString()) && finalNodeId !== y.id) {
      setSelectedNodeIds([...selectedNodeIds, ...nodeId]);
      const newEdgeConnection = {
        id: `reactflow__edge-${y.id}-${finalNodeId}`,
        source: `${y.id}`,
        sourceHandle: null,
        target: `${finalNodeId}`,
        targetHandle: null,
      };
      addedNewEdgeConnection = [...edges, newEdgeConnection];
      setEdges(addedNewEdgeConnection);
    } else if (
      selectedNodeIds.includes(nodeId.toString()) &&
      finalNodeId !== y.id
    ) {
      const edgeToRemoveId = `reactflow__edge-${y.id}-${finalNodeId}`;
      const updatedEdges = edges.filter((edge) => edge.id !== edgeToRemoveId);
      setEdges(updatedEdges);
      const updatedSelectedNodeIds = selectedNodeIds.filter(
        (id) => id !== nodeId.toString()
      );
      setSelectedNodeIds(updatedSelectedNodeIds);
    }

    const fetchedResult = getLayoutedElements(nodes, addedNewEdgeConnection);
    if (fetchedResult) {
      setNodes(fetchedResult.nodes);
      setEdges(fetchedResult.edges);
    }
  };

  return (
    <div className="CConfigMainContainer">
      <div className="CConfigCongurationArea">
        <div
          className="CConfigLeftArea"
          style={{ filter: disableConfigInputs ? "opacity(0.7)" : "none" }}
        >
          <ConfigureCard
            deviceName={selectedConnectorName}
            configs={configs}
            handleConfigFieldsInputChange={handleConfigFieldsInputChange}
            inputValues={inputValues}
          />
        </div>
        <div className="CConfigRightArea">
          {disableConfigInputs ? (
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              onNodeClick={handleNodeClick}
              nodesConnectable={false}
              connectionLineType={ConnectionLineType.Bezier}
              disableKeyboardA11y={true}
            />
          ) : (
            <SetupCard />
          )}
        </div>
      </div>
      <div className="CConfigButtonArea">
        {isConfigApisBeingCalled ? (
          <div className="CConfigButtonContainer">
            <div className="CConfigButtonContainerSpinner">
              <Spinner color="light" />
            </div>
          </div>
        ) : (
          <div className="CConfigButtonContainer">
            <NewButton
              fontSize="16px"
              backgroundColor="inherit"
              margin="0px 24px 0px 0px"
              text="Cancel"
              onClick={() => {
                handleCancelClick(1);
              }}
            />
            <NewButton
              fontSize="16px"
              backgroundColor="#41BAC3"
              text={disableConfigInputs ? "Deploy" : "Configure"}
              onClick={() => {
                handleButtonClick(disableConfigInputs ? 2 : 1);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectorsConfigurationPage;
